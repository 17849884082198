import { css } from '@emotion/react';
import React from 'react';
import { BP, inlineRichtext } from '../../styles/mixins';
import { useBreakpoint } from '../../lib/hooks';
import { ImageProps, Img } from '../Img';
import { Video, VideoProps } from '../Video';
import { RichText } from '../RichText';

export type MediaProps = {
  /** Image to display (if video is specified, used as placeholder) */
  image?: Partial<GatsbyTypes.PrismicImageInterface>;
  /** Optional alternate image to use on mobile */
  mobileImage?: Partial<GatsbyTypes.PrismicImageInterface>;
  /** Video to display */
  video?: string;
  /** Optional caption to display below media */
  caption?: string;
  /** Alignment of caption */
  captionAlign?: 'center' | 'left';
  rounded?: boolean;
} & Partial<ImageProps> &
  Partial<VideoProps>;

/**
 * Adaptive media item for images and videos with optional caption
 */
export function Media({
  image,
  video,
  mobileImage,
  caption,
  captionAlign = 'center',
  className,
  rounded = true,
  ...props
}: MediaProps) {
  const isMobile = useBreakpoint(`(${BP.xs})`, false),
    useImage = isMobile && mobileImage?.url ? mobileImage : image;

  return (
    <div
      css={css`
        overflow: hidden;
        grid-column: 1 / -1;
        vertical-align: middle;
      `}
      className={className as string}
    >
      {!!video ? (
        <div
          css={
            rounded &&
            css`
              border-radius: var(--radius-small);
              overflow: hidden;
            `
          }
        >
          <Video
            src={video}
            {...(!!image ? { placeholder: useImage!.url } : {})}
            {...props}
          />
        </div>
      ) : (
        <Img
          css={
            rounded &&
            css`
              border-radius: var(--radius-small);
            `
          }
          {...{ ...(useImage as object), ...props }}
        />
      )}
      {!!caption && (
        <div
          css={[
            css`
              ${inlineRichtext}
              color: var(--color-secondary);
              font-weight: 500;
              margin: 2.25rem 0;
              width: 60%;
            `,
            captionAlign === 'center' &&
              css`
                text-align: center;
                margin-left: auto;
                margin-right: auto;
              `
          ]}
        >
          <RichText content={caption} />
        </div>
      )}
    </div>
  );
}
