import { css } from '@emotion/react';
import React from 'react';
import { BP } from '../../styles/mixins';
import { Media } from '../Media';

export type MasonryGalleryProps = {
  /** Media items for the gallery */
  items: {
    image?: any;
    video?: string;
  }[];
  /** Number of columns the gallery has */
  columns: 1 | 2 | 3 | 4;
};

/**
 * Dynamic media gallery with masonry-style layout
 */
export function MasonryGallery({
  items,
  columns,
  ...props
}: MasonryGalleryProps) {
  return (
    <div
      css={[
        css`
          grid-column: 1 / -1;
          column-count: ${columns};
          column-gap: var(--gutter);
          break-inside: avoid;
          page-break-inside: avoid;
        `,
        columns > 2 &&
          css`
            @media (${BP.l}) {
              column-count: 3;
            }
            @media (${BP.m}) {
              column-count: 2;
            }
          `,
        columns > 1 &&
          css`
            @media (${BP.s}) {
              grid-column: 3 / span 8;
              column-count: 1;
            }
            @media (${BP.xs}) {
              grid-column: 1 / -1;
            }
          `,
        css`
          @media (${BP.xs}) {
            column-count: 1;
          }
        `
      ]}
      {...props}
    >
      {items &&
        items.map(({ image, video }: any, i: number) => (
          <Media
            css={css`
              display: inline-block;
              overflow: visible;
              width: 100%;
              margin-bottom: var(--gutter);
              /* HACK for Safari render bug */
              transform: translateX(0);
              @media (${BP.xs}) {
                margin-bottom: var(--margin);
              }
            `}
            video={video}
            image={image}
            key={i}
          />
        ))}
    </div>
  );
}
