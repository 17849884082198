import { css } from '@emotion/react';
import React, { ReactNode } from 'react';
import { reset } from 'satchel-css';
import { TYPESET } from '../../styles/mixins';
import { Link, LinkProps } from '../Link';

export type ButtonProps = {
  /** Href to link to */
  href?: string;
  /** Style of button */
  theme?: 'fill' | 'stroke';
  /** Color theme */
  color?: 'primary' | 'accent';
  /** Whether the button is busy */
  busy?: boolean;
  /** Whether the button is disabled */
  disabled?: boolean;
  /** Action to perform when clicked */
  onClick?(): void;
  children: ReactNode;
} & Partial<LinkProps>;

/**
 * Text button with states. <br />
 * Use it for CTA links, click handlers, and form submits.
 */
export function Button({
  href,
  theme = 'fill',
  color = 'primary',
  busy,
  disabled,
  children,
  ...props
}: ButtonProps) {
  const Element = href ? Link : 'button',
    buttonColor =
      color === 'primary' ? 'var(--color-primary)' : 'var(--color-accent)';

  return (
    <Element
      css={[
        !href &&
          css`
            ${reset('button')};
          `,
        css`
          ${TYPESET.scale1}
          display: inline-flex;
          align-items: center;
          cursor: pointer;
          font-weight: var(--font-weight-bold);
          transition: all 200ms ease;
          outline: none !important;
          text-decoration: none;
          padding: 16px 52px;
          line-height: 1;
          white-space: nowrap;
          border-radius: var(--radius-button);
          border: 2px solid ${buttonColor};
        `,
        theme === 'fill' &&
          css`
            background: ${buttonColor};
            color: var(--color-background);
          `,
        theme === 'stroke' &&
          css`
            color: ${buttonColor};
          `,
        busy &&
          css`
            opacity: 0.5;
            pointer-events: none;
          `,
        disabled &&
          css`
            pointer-events: none;
          `
      ]}
      {...(href ? { href } : {})}
      {...props}
    >
      {children}
    </Element>
  );
}
