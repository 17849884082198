import { css } from '@emotion/react';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { subgrid } from 'satchel-css';
import { BP } from '../styles/mixins';
import { Heading } from './Heading';
import { ProjectCard } from './ProjectCard';
import { Section } from './Section';

export type ProjectListProps = {
  /** Optional title */
  title?: string;
  /** Optional start point for the list */
  start?: number;
  /** Optional end point for the list */
  end?: number;
  /** Optional docId to exclude from list */
  docId?: string;
};

/**
 * Prismic slice section rendered as components
 */
export function ProjectList({ title, start, end, docId }: ProjectListProps) {
  const {
      prismicNavigation
    } = useStaticQuery<GatsbyTypes.ProjectListQuery>(graphql`
      query ProjectList {
        prismicNavigation {
          data {
            projects {
              project {
                uid
                type
                document {
                  ... on PrismicProject {
                    prismicId
                    url
                    data {
                      title {
                        text
                      }
                      description {
                        html
                      }
                      cover_image {
                        url
                        alt
                        fluid(maxWidth: 1860) {
                          ...GatsbyPrismicImageFluid
                        }
                      }
                      cover_image_mobile {
                        url
                        alt
                        fluid(maxWidth: 600) {
                          ...GatsbyPrismicImageFluid
                        }
                      }
                      inverted_cover
                    }
                  }
                }
              }
            }
          }
        }
      }
    `),
    { data } = prismicNavigation!;

  if (!data) {
    return null;
  }

  return (
    <>
      {title && (
        <Section
          fullWidth
          css={css`
            padding-top: 0;
            @media (${BP.xs}) {
              padding: 0 var(--mobile-text-gutter);
            }
          `}
        >
          <Heading centered scale="secondary">
            {title}
          </Heading>
        </Section>
      )}
      <div
        css={css`
          ${subgrid}
          grid-row-gap: var(--margin);
          margin-top: var(--padding);
        `}
      >
        {data.projects &&
          data.projects
            .filter(({ project }: any) => docId !== project.document.prismicId)
            .filter((item: any, i: number) => {
              const afterStart = start ? i + 1 >= start : true,
                beforeEnd = end ? i + 1 <= end : true;
              return afterStart && beforeEnd;
            })
            .map(({ project }: any, i: number) => {
              return <ProjectCard project={project?.document} key={i} />;
            })}
      </div>
    </>
  );
}
