import { css } from '@emotion/react';
import React from 'react';
import { innerHTML } from '../../lib/utils';
import { BP, primaryColor, TYPESET } from '../../styles/mixins';
import { Heading } from '../Heading';
import { RichText } from '../RichText';

export type TitleParagraphProps = {
  /** Title */
  title: string;
  /** HTML content */
  content: string;
};

/**
 * Title + paragraph content
 */
export function TitleParagraph({ title, content }: TitleParagraphProps) {
  return (
    <>
      <Heading
        css={css`
          grid-column: 3 / span 2;
          @media (${BP.l}) {
            grid-column: 3 / span 3;
          }
          @media (${BP.m}) {
            grid-column: 3 / span 8;
            margin-bottom: 34px;
          }
          @media (${BP.s}) {
            grid-column: 2 / span 10;
          }
          @media (${BP.xs}) {
            grid-column: 1 / -1;
            padding: 0 var(--mobile-text-gutter);
          }
        `}
        scale="secondary"
      >
        <RichText content={innerHTML(title)} />
      </Heading>
      <div
        css={css`
          ${TYPESET.scale1}
          ${primaryColor}
          grid-column: 5 / span 6;
          & > p:first-child {
            margin-top: 0.35rem;
          }
          @media (${BP.l}) {
            grid-column: 6 / span 5;
          }
          @media (${BP.m}) {
            grid-column: 3 / span 8;
          }
          @media (${BP.s}) {
            grid-column: 2 / span 10;
          }
          @media (${BP.xs}) {
            grid-column: 1 / -1;
            padding: 0 var(--mobile-text-gutter);
          }
        `}
      >
        <RichText content={content} />
      </div>
    </>
  );
}
