import { css } from '@emotion/react';
import React from 'react';
import { ReactComponent as DownloadIcon } from '../../assets/icons/download.svg';
import { BP, TYPESET } from '../../styles/mixins';
import { Img } from '../Img';
import { Link } from '../Link';

type Resource = {
  /** Image to use as icon */
  resource_thumbnail: {};
  /** Name of file */
  resource_name: string;
  /** Type of file */
  resource_format: string;
  /** Link to file */
  resource_file: {
    url: string;
  };
};

export type ResourcesProps = {
  items: Resource[];
  darkTheme: boolean;
};

/**
 * List of files
 */
export function Resources({ items, darkTheme, ...props }: ResourcesProps) {
  return (
    <div
      css={css`
        grid-column: 4 / span 6;
        background: ${darkTheme ? '#1c1c1c' : 'var(--color-grey-300)'};
        border-radius: 14px;
        @media (${BP.m}) {
          grid-column: 3 / span 8;
        }
        @media (${BP.s}) {
          grid-column: 2 / span 10;
        }
        @media (${BP.xs}) {
          grid-column: 1 / -1;
          margin: 0 var(--mobile-text-gutter);
        }
      `}
      {...props}
    >
      {items &&
        items.map(
          (
            {
              resource_thumbnail,
              resource_name,
              resource_format,
              resource_file
            }: Resource,
            i: number
          ) => (
            <div
              css={css`
                display: flex;
                align-items: stretch;
                padding: 0 32px;
                &:not(:last-of-type) .details {
                  border-bottom: 1px solid
                    ${darkTheme ? '#303030' : 'var(--color-grey-700)'};
                }
                @media (${BP.m}), (${BP.s}), (${BP.xs}) {
                  padding: 0 16px;
                }
              `}
            >
              <div
                css={css`
                  padding: 16px 0;
                  margin-right: 1rem;
                `}
              >
                <Img
                  css={css`
                    width: 32px;
                    height: 32px;
                  `}
                  {...resource_thumbnail}
                />
              </div>

              <div
                className="details"
                css={css`
                  display: flex;
                  width: 100%;
                  align-items: center;
                  justify-content: space-between;
                `}
              >
                <span
                  css={css`
                    display: inline-block;
                    ${TYPESET.scale1}
                    color: var(--color-primary);
                    font-weight: var(--font-weight-bold);
                  `}
                >
                  {resource_name}
                </span>
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                  `}
                >
                  <span
                    css={css`
                      display: inline-block;
                      ${TYPESET.scale00}
                      color: var(--color-tertiary);
                      margin-right: 1rem;
                      font-weight: var(--font-weight-medium);
                    `}
                  >
                    {resource_format}
                  </span>
                  <Link href={resource_file.url}>
                    <DownloadIcon
                      css={css`
                        width: 28px;
                        height: 28px;
                        stroke: var(--color-accent);
                      `}
                    />
                  </Link>
                </div>
              </div>
            </div>
          )
        )}
    </div>
  );
}
