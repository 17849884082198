import { css } from '@emotion/react';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { BP, contentGrid, TYPESET } from '../../styles/mixins';
import { RichText } from '../RichText';
/**
 * Global site footer
 */
export function Footer(props: object) {
  const { prismicFooter } = useStaticQuery<GatsbyTypes.FooterQuery>(graphql`
      query Footer {
        prismicFooter {
          data {
            social {
              html
            }
            location {
              html
            }
            legal {
              html
            }
          }
        }
      }
    `),
    { data } = prismicFooter!;

  if (!data) {
    return null;
  }

  return (
    <footer
      css={css`
        ${TYPESET.scale00}
        text-align: center;
        padding-bottom: calc(2 * var(--padding));
      `}
      {...props}
    >
      <div
        css={css`
          margin: var(--padding) 0 6.875rem;
          & a {
            text-decoration: none;
            font-weight: var(--font-weight-bold);
          }
        `}
      >
        <RichText content={data.social!.html || ''} />
        <div
          css={css`
            margin-top: 2.5rem;
          `}
        >
          <RichText content={data.location!.html || ''} />
        </div>
      </div>
      <div
        css={css`
          ${TYPESET.scale00}
          color: var(--color-secondary);
        `}
      >
        <RichText content={data.legal!.html || ''} />
      </div>
    </footer>
  );
}
