import { css } from '@emotion/react';
import React from 'react';

export type SeparatorProps = {
  theme: 'light' | 'dark';
};

/**
 * Separator line
 */
export function Separator({ theme, ...props }: SeparatorProps) {
  return (
    <div
      css={css`
        height: 1px;
        width: 100%;
        background: ${theme === 'light'
          ? 'var(--color-grey-700)'
          : 'var(--color-grey-900)'};
      `}
      {...props}
    ></div>
  );
}
