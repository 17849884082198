import { css } from '@emotion/react';
import React from 'react';
import ReactPlayer, { ReactPlayerProps } from 'react-player';
import { aspect } from 'satchel-css';
import { ReactComponent as PlayIcon } from '../../assets/icons/play.svg';
import { BP } from '../../styles/mixins';

export type VideoProps = {
  /** URL source of the video */
  src: string;
  /** Optional placeholder image to click before playing */
  placeholder?: string | boolean;
  /** Whether the video should fit its container like background-size: cover */
  cover?: boolean;
} & Partial<ReactPlayerProps>;

/**
 * Adaptive video player for local files and streaming services
 */
export function Video({ src, placeholder, cover, ...props }: VideoProps) {
  return (
    <ReactPlayer
      css={[
        !cover &&
          css`
            ${aspect(16, 8.75, { mode: 'absolute' })}
            width: 100%;
            background: black;
          `,
        cover &&
          css`
            width: 100%;
          `
      ]}
      url={src}
      width="100%"
      height="100%"
      light={placeholder}
      autoplay={false}
      controls={true}
      playIcon={
        <PlayIcon
          css={css`
            fill: white;
            width: 2.625rem;
            height: 2.625rem;
            @media (${BP.l}) {
              width: 2.25rem;
              height: 2.25rem;
            }
            @media (${BP.m}) {
              width: 1.75rem;
              height: 1.75rem;
            }
            @media (${BP.xs}) {
              width: 1.5625rem;
              height: 1.5625rem;
            }
          `}
        />
      }
      {...props}
    />
  );
}
