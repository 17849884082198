import { css } from '@emotion/react';
import React from 'react';
import { TYPESET } from '../../styles/mixins';

export type TagsProps = {
  /** List of tags */
  tags: string[];
};

/**
 * Tag list
 */
export function Tags({ tags, ...props }: TagsProps) {
  return (
    <span
      css={css`
        ${TYPESET.scale0}
        display: block;
      `}
      {...props}
    >
      {tags &&
        tags.map(
          (tag: string, i: number) =>
            `${tag}${i !== tags.length - 1 ? ', ' : ''}`
        )}
    </span>
  );
}
