import { css } from '@emotion/react';
import React from 'react';
import { aspect } from 'satchel-css';
import { useBreakpoint } from '../../lib/hooks';
import { BP, contentGrid } from '../../styles/mixins';
import { Headline } from '../Headline';
import { Img } from '../Img';
import { Media } from '../Media';

export type HeadlineImageProps = {
  /** Headline */
  headline: string;
  /** Background image */
  image: Partial<GatsbyTypes.PrismicImageInterface>;
  /** Background image for mobile devices */
  mobileImage?: Partial<GatsbyTypes.PrismicImageInterface>;
  /** Whether to show large headline */
  large?: boolean;
  /** Whether headline color is inverted */
  inverted?: boolean;
};

/**
 * Headline overlaid on an image
 */
export function HeadlineImage({
  headline,
  image,
  mobileImage,
  large,
  inverted,
  ...props
}: HeadlineImageProps) {
  const isMobile = useBreakpoint(`(${BP.xs})`, false),
    useImage = isMobile && mobileImage?.url ? mobileImage : image;

  return (
    <div
      css={css`
        ${aspect(16, 9, { mode: 'absolute' })}
        grid-column: 1 / -1;
        display: block;
        overflow: hidden;
        background: transparent;
        @media (${BP.xs}) {
          ${aspect(9, 16, { mode: 'absolute' })}
        }
      `}
      {...props}
    >
      <div
        css={css`
          display: grid;
          grid-template-columns: var(--page-grid);
          z-index: var(--layer-1);
        `}
      >
        <div
          css={css`
            ${contentGrid};
            grid-column: 2 / 3;
            position: absolute;
            top: 50%;
            width: 100%;
            transform: translateY(-50%);
          `}
        >
          <Headline
            css={
              inverted &&
              css`
                color: var(--color-primary-dark);
              `
            }
            headline={headline}
            large={large}
            alignment="Centered"
          />
        </div>
      </div>
      <Img {...useImage} />
    </div>
  );
}
