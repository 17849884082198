import { css } from '@emotion/react';
import React, { FormEvent, HTMLProps, useState } from 'react';
import { reset } from 'satchel-css';
import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow.svg';

export type InputFormProps = {
  onChange?(e: InputEvent): void;
  onSubmit?(e: FormEvent): void;
  busy?: boolean;
  valid?: boolean;
  className?: string;
} & Partial<HTMLProps<HTMLInputElement>>;

/**
 * Input with inline submit
 */
export function InputForm({
  className,
  onSubmit,
  value,
  valid,
  busy,
  ...props
}: InputFormProps) {
  return (
    <form
      css={[
        css`
          position: relative;
        `,
        busy &&
          css`
            opacity: 0.5;
            pointer-events: none;
          `
      ]}
      className={className}
      onSubmit={onSubmit}
    >
      <input
        css={[
          css`
            ${reset('input')}
            padding: 0 50px 0 22px;
            height: 53px;
            background: white;
            border-radius: 14px;
            border: 1px solid #f0f0f0;
            transition: border-color 150ms ease;
            outline: none;
            width: 100%;
            &:focus {
              border-color: #8ab8e6;
            }
            &::placeholder {
              opacity: 1;
              color: var(--color-grey-500);
            }
          `,
          !valid &&
            css`
              border-color: #ff8080 !important;
            `
        ]}
        value={value}
        {...props}
      />
      <button
        type="submit"
        css={css`
          ${reset('button')}
          position: absolute;
          right: 22px;
          top: 50%;
          transform: translateY(-50%);
        `}
      >
        <ArrowIcon
          css={css`
            width: 20px;
            height: 20px;
            stroke: ${!!value ? 'var(--color-black)' : 'var(--color-grey-500)'};
            transition: stroke 150ms ease;
          `}
        />
      </button>
    </form>
  );
}
