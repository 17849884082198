import { css } from '@emotion/react';
import React from 'react';
import { subgrid } from 'satchel-css';
import { BP } from '../../styles/mixins';
import { EaseIn } from '../EaseIn';
import { ImageProps } from '../Img';
import { Media } from '../Media';

type GalleryItem = {
  caption: { html: string };
  caption_alignment: 'center' | 'left';
} & ImageProps;

export type GalleryProps = {
  /** Images for the gallery */
  items: GalleryItem[];
  /** Whether to be large format (2 columns) */
  large: boolean;
};

/**
 * Image gallery with optional captions
 */
export function Gallery({ items, large, ...props }: GalleryProps) {
  return (
    <div
      css={css`
        ${subgrid}
        grid-row-gap: var(--margin);
      `}
      {...props}
    >
      {items &&
        items.map(
          (
            { caption, caption_alignment, ...image }: GalleryItem,
            i: number
          ) => (
            <EaseIn
              css={[
                css`
                  grid-column: ${large ? `span 4` : `span 6`};
                  @media (${BP.s}) {
                    grid-column: ${large ? `4 / span 6` : `3 / span 8`};
                  }
                  @media (${BP.xs}) {
                    grid-column: 1 / -1;
                  }
                `,
                !!caption &&
                  css`
                    margin-bottom: 1.875rem;
                  `
              ]}
              step={i}
              key={i}
            >
              <Media
                caption={caption.html}
                captionAlign={caption_alignment}
                image={{ ...image }}
              />
            </EaseIn>
          )
        )}
    </div>
  );
}
