import { css } from '@emotion/react';
import React from 'react';
import { BP, primaryColor, TYPESET } from '../../styles/mixins';
import { RichText } from '../RichText';

export type ParagraphProps = {
  /** HTML string of richtext content */
  content: string;
};

export const paragraphLayout = css`
  @media (${BP.l}), (${BP.xl}) {
    grid-column: 4 / span 6;
  }
  @media (${BP.m}) {
    grid-column: 3 / span 8;
  }
  @media (${BP.s}) {
    grid-column: 2 / span 10;
  }
  @media (${BP.xs}) {
    grid-column: 1 / -1;
    padding: 0 var(--mobile-text-gutter);
  }
`;

/**
 * Responsive copy/paragraph component
 */
export function Paragraph({ content, ...props }: ParagraphProps) {
  return (
    <div
      css={[
        css`
          ${TYPESET.scale1}
          ${primaryColor}
        `,
        paragraphLayout
      ]}
      {...props}
    >
      <RichText {...{ content }} />
    </div>
  );
}
