import { css, keyframes } from '@emotion/react';
import React, { ReactNode, useState } from 'react';
import { useInView } from 'react-intersection-observer';

export type EaseInProps = {
  /** Delay factor for the animation, for staggering ease-ins */
  step?: number;
  /** Content to be eased in */
  children: ReactNode;
};

const duration = 500,
  easeIn = keyframes`
    from {
      opacity: 0;
      transform: translateY(3rem);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  `;

/**
 * Animation helper that eases in content when it enters the viewport
 */
export function EaseIn({ step = 0, children, ...props }: EaseInProps) {
  const [ref, inView] = useInView({
      threshold: 0.3
    }),
    [triggered, setTriggered] = useState(false);

  if (inView && !triggered) {
    setTriggered(true);
  }

  return (
    <div
      ref={ref}
      css={[
        css`
          opacity: 0;
        `,
        triggered &&
          css`
            animation: ${easeIn} ${duration}ms var(--easing-decelerate) forwards;
            animation-delay: ${(duration / 2) * step}ms;
          `
      ]}
      {...props}
    >
      {children}
    </div>
  );
}
