import { css } from '@emotion/react';
import React from 'react';
import { BP, primaryColor, TYPESET } from '../../styles/mixins';
import { RichText } from '../RichText';

export type CalloutProps = {
  /** HTML richtext string of content */
  content: string;
  /** Whether the callout is larger */
  large?: boolean;
  /** Whether the callout is left aligned by default */
  leftAlign?: boolean;
};

export const calloutLayout = css`
  @media (${BP.xl}), (${BP.l}) {
    grid-column: 3 / span 8;
  }
  @media (${BP.m}) {
    grid-column: 2 / span 10;
  }
  @media (${BP.xs}) {
    grid-column: 1 / -1;
    padding: 0 var(--mobile-text-gutter);
  }
`;

/**
 * Short piece of text for introdcutions and announcements
 */
export function Callout({ content, large, leftAlign, ...props }: CalloutProps) {
  return (
    <div
      css={[
        css`
          ${TYPESET.scale2}
          ${calloutLayout}
          ${primaryColor}
          & > p:first-of-type {
            margin: 0;
          }
        `,
        !leftAlign &&
          css`
            text-align: center;
            @media (${BP.s}) {
              text-align: left;
            }
          `,
        large &&
          css`
            ${TYPESET.scale3}
          `
      ]}
      {...props}
    >
      <RichText {...{ content }} />
    </div>
  );
}
