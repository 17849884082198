import { css } from '@emotion/react';
import React, { ReactNode } from 'react';
import { TYPESET } from '../../styles/mixins';

export type HeadingProps = {
  /** Size of the heading, also determines corresponding <h?> tag */
  scale?: keyof typeof ELEMENTS;
  /** Whether heading is centered, in both alignment and justification */
  centered?: boolean;
  /** Content of the heading */
  children: ReactNode;
};

const ELEMENTS = {
  primary: 'h1',
  display: 'h1',
  secondary: 'h2',
  tertiary: 'h3'
};

/**
 * Responsive heading component with various sizes
 */
export function Heading({
  scale = 'secondary',
  centered,
  children,
  ...props
}: HeadingProps) {
  const Element = ELEMENTS[scale] as any;

  return (
    <Element
      css={[
        css`
          grid-column: 1 / -1;
          color: var(--color-primary);
        `,
        scale === 'display' &&
          css`
            ${TYPESET.scale5}
            font-weight: 700;
          `,
        scale === 'primary' &&
          css`
            ${TYPESET.scale4}
            font-weight: 700;
          `,
        scale === 'secondary' &&
          css`
            ${TYPESET.scale3}
            font-weight: var(--font-weight-bold);
          `,
        scale === 'tertiary' &&
          css`
            ${TYPESET.scale2}
            font-weight: var(--font-weight-bold);
          `,
        centered &&
          css`
            text-align: center;
            margin-left: auto;
            margin-right: auto;
          `
      ]}
      {...props}
    >
      {children}
    </Element>
  );
}
