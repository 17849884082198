import { css } from '@emotion/react';
import isDarkColor from 'is-dark-color';
import React, { ReactNode } from 'react';
import { subgrid } from 'satchel-css';
import { BP, contentGrid, primaryColor } from '../../styles/mixins';

export type SectionProps = {
  /** Amount of vertical spacing the section has */
  spacing?: 'none' | 'single' | 'double';
  /** Optional background colors for gradient of the section */
  background?: string[];
  /** Whether content of the section extend to edges of the page */
  fullWidth?: boolean;
  /** Content of the section */
  children?: ReactNode;
};

/**
 * Section of content with appropriate slice padding
 */
export function Section({
  children,
  background,
  spacing = 'single',
  fullWidth,
  ...props
}: SectionProps) {
  const hasDarkBackground =
    background &&
    ((background[0] && isDarkColor(background[0])) ||
      (background[1] && isDarkColor(background[1])));

  return (
    <section
      css={[
        css`
          ${subgrid}
        `,
        background &&
          css`
            background: linear-gradient(
              ${background[0]},
              ${background[1] || background[0]}
            );
          `,
        hasDarkBackground &&
          css`
            --color-primary: var(--color-primary-dark);
            --color-secondary: var(--color-secondary-dark);
            --color-tertiary: var(--color-tertiary-dark);
            --color-background: background[0];
          `,
        spacing !== 'none' &&
          css`
            padding: ${spacing === 'single'
                ? 'var(--padding)'
                : 'calc(var(--padding)*2)'}
              0;
          `
      ]}
      {...props}
    >
      <div
        css={css`
          grid-column: ${fullWidth ? '1 / -1' : '2 / 3'};
          ${contentGrid}
        `}
      >
        {children}
      </div>
    </section>
  );
}
