import { css } from '@emotion/react';
import React, { useEffect, useRef } from 'react';
import useDimensions from 'react-use-dimensions';
import { aspect } from 'satchel-css';
import { BP } from '../../styles/mixins';
import { Heading } from '../Heading';
import { ImageProps, Img } from '../Img';

export type ScrollCarouselProps = {
  /** Title of the carousel */
  title: string;
  /** Images to show in the carousel */
  items: ImageProps[];
};

const MIN_SCALE = 0.75,
  MIN_OPACITY = 0;

/**
 * Vertical scrolling sticky carousel with a card stacking effect
 */
export function ScrollCarousel({
  title,
  items,
  ...props
}: ScrollCarouselProps) {
  const [containerRef, { width }] = useDimensions(),
    animateCards = (entries: IntersectionObserverEntry[]) => {
      entries.forEach(({ boundingClientRect }, i) => {
        if (!entries[i - 1]) {
          return;
        }

        window.requestAnimationFrame(() => {
          const { target: prev } = entries[i - 1] as any,
            progress = boundingClientRect.top / (window.innerHeight * 0.5);

          prev.style.opacity = Math.max(MIN_OPACITY, progress).toString();
          prev.style.transform = `translateY(-50%) scale(${Math.max(
            MIN_SCALE,
            Math.min(1, MIN_SCALE + (1 - MIN_SCALE) * progress)
          )})`;
        });
      });
    };

  // HACK for SSR
  if (typeof window === 'undefined') {
    return null;
  }

  const observer = new IntersectionObserver(animateCards, {
    threshold: 0
    // rootMargin: '0px 0px 50% 0px'
  });

  useEffect(() => {
    return () => observer.disconnect();
  }, []);

  return (
    <div
      ref={containerRef}
      css={css`
        position: relative;
        grid-column: 4 / span 6;
        @media (${BP.m}) {
          grid-column: 3 / span 8;
        }
        @media (${BP.s}) {
          grid-column: 2 / span 10;
        }
        @media (${BP.xs}) {
          grid-column: 1 / -1;
        }
      `}
      {...props}
    >
      <Heading centered>{title}</Heading>
      <div
        css={css`
          margin-top: ${width / 2}px;
          margin-bottom: -${width / 2}px;
          pointer-events: none;
        `}
      >
        {items &&
          items.map((image: ImageProps, i: number) => {
            const ref = useRef(null),
              refreshObserver = () => {
                if (!ref.current) {
                  return;
                }
                observer.unobserve(ref.current!);
                setTimeout(() => observer.observe(ref.current!), 0);
              };

            ref.current && observer.observe(ref.current!);

            useEffect(() => {
              window.addEventListener('resize', refreshObserver);
              return window.removeEventListener('resize', refreshObserver);
            }, []);

            return (
              <div
                css={css`
                  position: sticky;
                  top: calc(50% - var(--padding));
                  padding-top: calc(var(--padding));
                  ${i !== 0 &&
                  css`
                    margin-top: calc(100vh - 125%);
                  `}
                  z-index: ${10 * i};
                `}
                key={i}
              >
                <div
                  ref={ref}
                  css={css`
                    transform: translateY(-50%);
                    transform-origin: center top;
                  `}
                >
                  <Img
                    css={css`
                      ${aspect(1, 1, { mode: 'absolute' })}
                      width: 100%;
                      border-radius: var(--radius-small);
                      & img {
                        pointer-events: auto;
                      }
                    `}
                    {...image}
                  />
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
