import { css } from '@emotion/react';
import React from 'react';
import { BP, primaryColor } from '../../styles/mixins';
import { RichText } from '../RichText';

export type WhisperProps = {
  /** HTML string of richtext content */
  content: string;
};

/**
 * Short piece of text for asides
 */
export function Whisper({ content, ...props }: WhisperProps) {
  return (
    <div
      css={css`
        ${primaryColor}
        @media (${BP.xl}) {
          grid-column: 3 / span 4;
        }
        @media (${BP.l}) {
          grid-column: 3 / span 5;
        }
        @media (${BP.m}) {
          grid-column: 2 / span 6;
        }
        @media (${BP.s}) {
          grid-column: 2 / span 8;
        }
        @media (${BP.xs}) {
          grid-column: 1 / -1;
          padding: 0 var(--mobile-text-gutter);
        }
      `}
      {...props}
    >
      <RichText {...{ content }} />
    </div>
  );
}
