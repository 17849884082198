import { css } from '@emotion/react';
import React from 'react';
import { innerHTML } from '../../lib/utils';
import { Alignment, BP, setAlignment } from '../../styles/mixins';
import { Heading } from '../Heading';
import { RichText } from '../RichText';

export type HeadlineProps = {
  /** HTML string of the headline */
  headline: string;
  /** Whether headline is large */
  large: boolean;
  /** Alignment of headling */
  alignment?: Alignment;
};

/**
 * Styled full-width heading
 */
export function Headline({
  headline,
  large,
  alignment,
  ...props
}: HeadlineProps) {
  return (
    <div
      css={css`
        ${setAlignment(alignment)}
        grid-column: 3 / span 8;
        @media (${BP.m}) {
          grid-column: 2 / span 10;
        }
        @media (${BP.xs}) {
          grid-column: 1 / -1;
          padding: 0 var(--mobile-text-gutter);
        }
      `}
      {...props}
    >
      <Heading scale={large ? 'display' : 'primary'}>
        <RichText content={innerHTML(headline)} />
      </Heading>
    </div>
  );
}
