import { css } from '@emotion/react';
import React from 'react';
import { LinkProps } from 'react-helmet-async';
import HTML from 'react-html-renderer';
import { Heading, HeadingProps } from '../Heading';
import { Link } from '../Link';

export type RichTextProps = {
  /** HTML content to render */
  content: string;
};

/**
 * Render HTML strings with custom components
 */
export function RichText({ content, ...props }: RichTextProps) {
  return (
    <HTML
      html={content}
      components={{
        h1: ({ children, ...props }: HeadingProps) => (
          <Heading scale="primary" {...props}>
            {children}
          </Heading>
        ),
        h2: ({ children, ...props }: HeadingProps) => (
          <Heading
            scale="secondary"
            css={css`
              margin-bottom: 34px;
            `}
            {...props}
          >
            {children}
          </Heading>
        ),
        h3: ({ children, ...props }: HeadingProps) => (
          <Heading
            scale="tertiary"
            css={css`
              margin-bottom: 27px;
            `}
            {...props}
          >
            {children}
          </Heading>
        ),
        a: ({ children, ...props }: LinkProps | any) => (
          <Link
            css={css`
              color: var(--color-accent);
            `}
            {...props}
          >
            {children}
          </Link>
        )
      }}
      {...props}
    />
  );
}
