import { css, keyframes } from '@emotion/react';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useRef, useState } from 'react';
import { HamburgerIcon } from 'react-hamburger-icon';
import { Overlay } from 'react-portal-overlay';
import { BP, subPageGrid, TYPESET } from '../../styles/mixins';
import { EaseIn } from '../EaseIn';
import { Link } from '../Link';

export type HeaderProps = {
  /** Optional page title to display */
  title?: string;
  /** Whether to hide the menu */
  noMenu?: boolean;
  /** Whether to invert colors */
  inverted?: boolean;
};

const scaleIn = keyframes`
  from {
    transform: scaleY(0);
  }
  to {
    transform: scaleY(1);
  }
`;

/**
 * Global header component
 */
export function Header({ title, noMenu, inverted, ...props }: HeaderProps) {
  const { prismicNavigation } = useStaticQuery<GatsbyTypes.HeaderQuery>(graphql`
      query Header {
        prismicNavigation {
          data {
            menu {
              menu_link {
                url
              }
              menu_label
            }
          }
        }
      }
    `),
    { data } = prismicNavigation!,
    [menuOpen, setMenuOpen] = useState(false),
    menu = useRef<HTMLDivElement>(null);

  if (!data) {
    return null;
  }

  return (
    <>
      <header
        css={[
          css`
            ${subPageGrid}
            padding: var(--margin) 0;
            position: relative;
            transition: color 300ms ease;
            @media (${BP.xs}) {
              padding: calc(var(--margin) * 2) 12px;
            }
          `,
          menuOpen &&
            css`
              color: white;
            `,
          inverted &&
            css`
              --color-primary: var(--color-primary-dark);
            `
        ]}
        {...props}
      >
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
          `}
        >
          <h1
            css={[
              css`
                ${TYPESET.scale1}
                font-weight: var(--font-weight-bold);
                transition: opacity 300ms ease;
                color: var(--color-primary);
              `,
              menuOpen &&
                css`
                  opacity: 0;
                `
            ]}
          >
            {title}
          </h1>
          {!noMenu && (
            <div
              css={css`
                padding: 12px;
                margin: -12px;
                cursor: pointer;
                z-index: var(--layer-top);
                position: relative;
              `}
              onClick={() => setMenuOpen(!menuOpen)}
            >
              <HamburgerIcon
                open={menuOpen}
                duoLine
                css={css`
                  color: ${menuOpen ? 'white' : 'var(--color-primary)'};
                  transition: color 250ms ease;
                  height: 8px !important;
                  width: 20px !important;
                  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
                `}
              />
            </div>
          )}
        </div>
      </header>

      {/* Mobile menu */}
      <div
        ref={menu}
        css={css`
          position: relative;
          z-index: var(--layer-5);
        `}
      ></div>
      {!noMenu && (
        <Overlay
          css={css`
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background: var(--color-background-dark);
            color: white;
            transform-origin: top center;
            transform: scaleY(0);
            animation: ${scaleIn} 300ms var(--easing-decelerate) 400ms forwards;
          `}
          open={menuOpen}
          portal={menu.current}
          onClose={() => setMenuOpen(false)}
        >
          {data.menu &&
            data.menu.map(({ menu_link, menu_label }: any, i: number) => (
              <EaseIn
                css={css`
                  margin: 1rem 0;
                `}
                step={i + 2.5}
                key={i}
              >
                <Link
                  css={css`
                    ${TYPESET.scale3}
                    font-weight: var(--font-weight-bold);
                    color: inherit;
                    text-decoration: none;
                  `}
                  href={menu_link.url}
                  onClick={() => setMenuOpen(false)}
                >
                  {menu_label}
                </Link>
              </EaseIn>
            ))}
        </Overlay>
      )}
    </>
  );
}
