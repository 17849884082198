import { css } from '@emotion/react';
import { graphql, useStaticQuery } from 'gatsby';
import isDarkColor from 'is-dark-color';
import React from 'react';
import { BP, TYPESET } from '../styles/mixins';
import { Heading } from './Heading';
import { Link } from './Link';
import { Section } from './Section';
import { Tags } from './Tags';

export type JournalListProps = {
  /** Optional title */
  title?: string;
  /** Optional start point for the list */
  start?: number;
  /** Optional end point for the list */
  end?: number;
  /** Optional docId to exclude from list */
  docId?: string;
  background?: string[];
};

/**
 * Prismic slice section rendered as components
 */
export function JournalList({
  title,
  start,
  end,
  background,
  docId
}: JournalListProps) {
  const {
      prismicNavigation
    } = useStaticQuery<GatsbyTypes.JournalListQuery>(graphql`
      query JournalList {
        prismicNavigation {
          data {
            journal {
              journal_title {
                text
              }
              article {
                uid
                type
                document {
                  ... on PrismicProject {
                    id
                    url
                    tags
                    data {
                      title {
                        text
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `),
    { data } = prismicNavigation!,
    hasDarkBackground =
      background &&
      ((background[0] && isDarkColor(background[0])) ||
        (background[1] && isDarkColor(background[1])));

  if (!data) {
    return null;
  }

  return (
    <>
      {title && (
        <Section
          css={css`
            padding-top: 0;
            @media (${BP.xs}) {
              padding: 0 var(--mobile-text-gutter);
            }
          `}
          fullWidth
        >
          <Heading centered scale="secondary">
            {title}
          </Heading>
        </Section>
      )}
      <div
        css={css`
          grid-column: 4 / span 6;
          margin-top: calc(var(--padding) - 32px);
          @media (${BP.l}) {
            grid-column: 3 / span 8;
          }
          @media (${BP.m}) {
            grid-column: 2 / span 10;
          }
          @media (${BP.s}) {
            grid-column: 2 / span 10;
          }
          @media (${BP.xs}) {
            grid-column: 1 / -1;
            padding: 0 var(--mobile-text-gutter);
          }
        `}
      >
        {data.journal &&
          data.journal
            .filter(
              ({ article }: any) => docId !== article?.document?.prismicId
            )
            .filter((item: any, i: number) => {
              const afterStart = start ? i + 1 >= start : true,
                beforeEnd = end ? i + 1 <= end : true;
              return afterStart && beforeEnd;
            })
            .map(({ article, journal_title }: any, i: number) => {
              if (!article?.document) {
                return null;
              }
              return (
                <div
                  css={css`
                    grid-column: 1 / -1;
                    padding: 32px 0;
                    &:not(:last-of-type) {
                      border-bottom: 1px solid
                        ${hasDarkBackground
                          ? 'var(--color-grey-900)'
                          : 'var(--color-grey-700)'};
                    }
                  `}
                >
                  <Tags
                    css={css`
                      ${TYPESET.scale00}
                      font-weight: var(--font-weight-medium);
                      color: var(--color-grey-600);
                      margin-bottom: 12px;
                    `}
                    tags={article.document.tags}
                  />
                  <Link
                    css={css`
                      ${TYPESET.scale2}
                      color: var(--color-primary);
                      font-weight: var(--font-weight-bold);
                    `}
                    href={article.document.url}
                  >
                    {journal_title.text}
                  </Link>
                </div>
              );
            })}
      </div>
    </>
  );
}
