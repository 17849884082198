import { css } from '@emotion/react';
import React from 'react';
import { aspect } from 'satchel-css';
import { BP, TYPESET } from '../../styles/mixins';
import { Img } from '../Img';
import { Link } from '../Link';
import { RichText } from '../RichText';
import { useBreakpoint } from '../../lib/hooks';

export type ProjectCardProps = {
  /** Project data document */
  project: GatsbyTypes.PrismicProject;
};

/**
 * Rich media link card for projects
 */
export function ProjectCard({ project, ...props }: ProjectCardProps) {
  if (!project || !project.data) {
    return null;
  }
  const {
      title,
      description,
      cover_image,
      cover_image_mobile,
      inverted_cover
    } = project.data,
    isMobile = useBreakpoint(`(${BP.xs})`, false),
    preferredImage = isMobile ? cover_image_mobile : cover_image;

  return (
    <Link
      href={project?.url || ''}
      css={css`
        ${aspect(16, 9, { mode: 'absolute' })}
        grid-column: 1 / -1;
        display: block;
        border-radius: var(--radius-large);
        overflow: hidden;
        background: transparent;
        @media (${BP.xs}) {
          ${aspect(9, 16, { mode: 'absolute' })}
        }
      `}
      {...props}
    >
      <Img
        {...(preferredImage && preferredImage.url
          ? preferredImage
          : cover_image)}
        cover
      />
      <div
        css={[
          css`
            position: relative;
            z-index: var(--layer-1);
            padding: var(--margin);
            color: var(--color-primary-light);
            @media (${BP.xs}) {
              padding: calc(3 * var(--margin));
            }
          `,
          inverted_cover &&
            css`
              color: var(--color-primary-dark);
            `
        ]}
      >
        <span
          css={css`
            ${TYPESET.scale00}
            display: block;
            font-weight: 500;
          `}
        >
          {title ? title.text : ''}
        </span>
        <div
          css={css`
            ${TYPESET.scale3}
            margin-top: 0.75rem;
            font-weight: var(--font-weight-bold);
            & p {
              margin: 0;
            }
          `}
        >
          <RichText content={description ? (description.html as string) : ''} />
        </div>
      </div>
    </Link>
  );
}
