import { css } from '@emotion/react';
import React, { FormEvent, useState } from 'react';
import { ReactComponent as LockIcon } from '../../assets/icons/lock.svg';
import { InputForm } from '../../components/InputForm';
import { Meta } from '../../components/Meta';
import { Section } from '../../components/Section';
import { BP } from '../../styles/mixins';

export function PrivatePage({
  docId,
  onAuthenticate
}: {
  docId: string;
  onAuthenticate(authenticated: boolean): void;
}) {
  const [state, setState] = useState({
    password: '',
    busy: false,
    valid: true
  });

  async function authenticate() {
    setState({ ...state, busy: true });
    const result = await fetch('/.netlify/functions/authenticate', {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          password: state.password,
          docId
        })
      }),
      { authenticated } = await result.json();

    setState({ ...state, busy: false, valid: authenticated });
    onAuthenticate(authenticated);
  }

  return (
    <>
      <Meta title="Private" />
      <Section
        css={css`
          background: var(--color-grey-100);
          min-height: 100vh;
          align-items: center;
        `}
      >
        <div
          css={css`
            display: flex;
            grid-column: 1 / -1;
            justify-content: center;
            padding-bottom: var(--margin);
            margin-top: calc(0px - var(--margin) - 72px);
          `}
        >
          <LockIcon
            css={css`
              width: 72px;
              height: 72px;
            `}
          />
        </div>
        <div
          css={css`
            grid-column: 5 / span 4;
            @media (${BP.l}) {
              grid-column: 4 / span 6;
            }
            @media (${BP.m}) {
              grid-column: 3 / span 8;
            }
            @media (${BP.s}) {
              grid-column: 2 / span 10;
            }
            @media (${BP.xs}) {
              grid-column: 1 / -1;
            }
          `}
        >
          <InputForm
            type="password"
            placeholder="Password"
            onChange={(e: any) =>
              setState({ ...state, password: e.target.value })
            }
            value={state.password}
            busy={state.busy}
            valid={state.valid}
            onSubmit={(e: FormEvent) => {
              e.preventDefault();
              authenticate();
            }}
          />
        </div>
      </Section>
    </>
  );
}
